import type { UseFetchOptions } from 'nuxt/app';

//todo здесь нужно добавить какой то хендлер ошибок и примешать его в ответ
export function usePaymentApi<T>(
    url: string | (() => string),
    options?: UseFetchOptions<T>,
) {
    const {$paymentApiV1} = useNuxtApp();
    return useFetch(url, {
        ...options,
        $fetch:$paymentApiV1,
    })
}